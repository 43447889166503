import React, { Component } from "react";
import { motion } from "framer-motion";
import Container from "./container";

import profile from "../images/ore.svg";
import linkedin from "../images/linkedin.svg";
import medium from "../images/medium.svg";
import dribbble from "../images/dribble.svg";
import { Link } from "gatsby";

export class Footer extends Component {
  render() {
    const variants = {
      hidden: {
        y: 40,
        opacity: 0,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: { delay: 1, duration: 1 },
      },
    };

    return (
      <footer
        className="footer"
        variants={variants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Container>
          <motion.div className="footer__content" 
          variants={variants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          >
            <img src={profile} alt="Oreoluwa's Illustration" />
            <div className="right">
              <a href="mailto:hi@oreoluwanimi.com" className="email">
                hi@oreoluwanimi.com
              </a>
              <div className="social">
                <a href="#">
                  <img src={linkedin} alt="Linkedin" />
                </a>
                <a href="#">
                  <img src={medium} alt="Medium" />
                </a>
                <a href="#">
                  <img src={dribbble} alt="Dribbble" />
                </a>
              </div>
              <div className="footer__links">
                <Link to="/">Home</Link>
                <Link to="/">Work</Link>
                <Link to="/about">About</Link>
                <Link to="/writing">Writings</Link>
                <Link to="/resources">Resources</Link>
                <Link to="/contact">Contact</Link>
              </div>
            </div>
          </motion.div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
